import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({
  children,
  showModal,
  setShowModal,
  buttonChoices,
  setButtonChoices,
}) => {
  const closeModal = () => {
    setShowModal(false);
    setButtonChoices([]);
  };
  const handleOptionClick = (optionClick) => {
    if (optionClick) optionClick();
    closeModal();
  };
  return (
    <>
      {showModal && (
      <>
        <div
          style={{
            backgroundColor: '#011454',
            opacity: 0.5,
            height: '100%',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: '1000',
          }}
          onClick={closeModal}
          role="presentation"
        />
        <div style={{
          backgroundColor: '#ffffff',
          opacity: 2,
          height: '80%',
          width: '80%',
          position: 'fixed',
          top: '10%',
          left: '10%',
          zIndex: '1001',
        }}
        >
          <button
            type="button"
            onClick={closeModal}
            style={{
              border: '4px solid #112464',
              backgroundColor: 'white',
              color: '#112464',
              fontSize: '3em',
              fontStyle: 'bold',
              float: 'right',
            }}
            alt="Close Modal"
          >
            X
          </button>
          <div style={{
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          >
            <div style={{
              margin: 0,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              minWidth: '200px',
            }}
            >
              {children}
              {buttonChoices.map((option) => (
                <button
                  key={option.text}
                  type="button"
                  className={option.className || 'stripe-button'}
                  onClick={() => handleOptionClick(option.onClick)}
                >
                  {option.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      </>
      )}
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setButtonChoices: PropTypes.func,
  buttonChoices: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
};

Modal.defaultProps = {
  buttonChoices: [],
  setButtonChoices: () => {},
};

export default Modal;
