import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import axios from 'axios';
import Layout from '../components/layout';
import Create from '../components/create';

import SEO from '../components/seo';
import LoadingSpinner from '../components/loading-spinner';

const CodePage = ({ location }) => {
  const { player2 } = queryString.parse(location.search);
  const [challengeId, setChallengeId] = useState('');
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.ABAMATH_API_URL}/code-championship/projects/challenge-id`,
      params: { botId: player2 },
    }).then((response) => {
      setChallengeId(response.data.challengeId);
    });
  }, []);
  return (
    <>
      <Layout>
        <SEO
          title="Share and Compete | Write Code at Code Championship"
          description="Share your code and compete against your friends to see who can build the best bot!"
          keywords={['share', 'compete', 'friends']}
        />
        <h1>Can you beat this bot?</h1>
        <div className="stripe-hr thin" />
        <p>
          Welcome to Code Championship! Your friend has shared this link
          so that you can write code to play head to head against them!
          Can you beat bot number
          {' '}
          {player2}
          ?
        </p>
        {challengeId ? (
          <Create
            currentChallenge={{ id: challengeId }}
            player2={player2}
          />
        ) : <LoadingSpinner />}
      </Layout>
    </>
  );
};

CodePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default CodePage;
