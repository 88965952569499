import React from 'react';
import PropTypes from 'prop-types';

const StandardOutboundLink = ({ href, children, className }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={className || ''}
  >
    {children || href}
    <img
      height="10px"
      width="10px"
      style={className === 'white-link' ? { filter: 'invert(1)' } : {}}
      alt=""
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg=="
    />
  </a>
);

StandardOutboundLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

StandardOutboundLink.defaultProps = {
  children: null,
  className: null,
};

export default StandardOutboundLink;
