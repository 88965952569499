import React from 'react';
import PropTypes from 'prop-types';

const StandardOutboundButton = ({ href, children, ...otherProps }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    <button
      type="button"
      {...otherProps}
    >
      {children || href}
      {'  →'}
    </button>
  </a>
);

StandardOutboundButton.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default StandardOutboundButton;
