import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import queryString from 'query-string';
import Modal from './modal';
import StandardOutboundLink from './standard-outbound-link';
import StandardOutboundButton from './standard-outbound-button';
import usePreviousBots from '../hooks/use-previous-bots';

const Create = ({
  currentChallenge, playerId, eventId, playerSecretKey, onCreate, player2,
}) => {
  const [cookies] = useCookies();
  const cookieBot = Object.values(cookies?.botList || {}).find((bot) => {
    const matchingEvent = bot.eventId === eventId;
    const matchingChallenge = bot.challengeId === currentChallenge.id;
    const matchingPlayer = bot.playerId === playerId;
    return matchingEvent && matchingChallenge && matchingPlayer;
  }) || null;
  const urlConstructor = ({ botId, botSecretKey }) => {
    const queryObject = {
      player1: botId,
      botSecretKey,
      player2,
    };
    return `${process.env.CODE_CHAMPIONSHIP_APP_URL}/#?${queryString.stringify(queryObject)}`;
  };
  const [botLink, setBotLink] = useState(cookieBot ? urlConstructor(cookieBot) : '');
  const [botNumber, setBotNumber] = useState(cookieBot?.botId || '');
  const [botChallengeId, setBotChallengeId] = useState(cookieBot?.challengeId || '');
  const [alertMessage, setAlertMessage] = useState('');
  const { addBot } = usePreviousBots();

  const createProject = () => {
    setBotLink('');
    setBotNumber('');
    axios({
      method: 'POST',
      url: `${process.env.ABAMATH_API_URL}/code-championship/projects/new-player-project`,
      data: {
        challengeId: currentChallenge.id,
        playerId,
        eventId,
        playerSecretKey,
      },
    }).then((response) => {
      setBotChallengeId(response.data.challengeId);
      setBotNumber(response.data.id);
      const newBot = {
        botId: response.data.id,
        botSecretKey: response.data.botSecretKey,
        eventId,
        challengeId: currentChallenge.id,
        playerId,
      };
      addBot(newBot);
      const newBotUrl = urlConstructor({
        botId: response.data.id,
        botSecretKey: response.data.botSecretKey,
      });
      setBotLink(newBotUrl);
      onCreate();
      const newTab = window.open(newBotUrl, '_blank');
      const newTabBlocked = !newTab || newTab.closed || typeof newTab.closed === 'undefined';
      if (!newTabBlocked) newTab.focus();
    }).catch(() => {
      setAlertMessage('There was an error while creating the new bot. If this happens a lot, send us an email!');
    });
  };

  const allBotsArray = Object.values(cookies?.botList || {});
  // eslint-disable-next-line max-len
  const existingBots = allBotsArray.filter((bot) => {
    const isValidForThisEvent = !eventId || eventId === bot.eventId;
    const isValidForThisChallenge = bot.challengeId === currentChallenge.id;
    return isValidForThisEvent && isValidForThisChallenge;
  });

  return (
    <>
      <Modal
        showModal={!!alertMessage}
        setShowModal={setAlertMessage}
      >
        <>{alertMessage}</>
      </Modal>
      <div style={{ padding: '10px 30px' }}>
        <h3>{currentChallenge.title}</h3>
        <p>{currentChallenge.description}</p>
        {(botChallengeId === currentChallenge.id && botLink) && (
          <>
            <div style={{ fontSize: '2rem' }}>
              {`Your bot Number is: ${botNumber}`}
            </div>
            <div>
              <small>
                If the button below doesn&apos;t work,
                {' '}
                <StandardOutboundLink href={botLink}>
                  try this link
                </StandardOutboundLink>
              </small>
            </div>
            <div>
              <StandardOutboundButton href={botLink} className="stripe-button">
                Let&apos;s Go!
              </StandardOutboundButton>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <p>
              Bot broken? Need a new bot number?
            </p>
          </>
        )}
        <button
          type="button"
          onClick={createProject}
          className={`stripe-button${(botChallengeId === currentChallenge.id && botLink) ? ' secondary' : ''}`}
        >
          Create a new bot!
        </button>
        { existingBots.length > 0 && (
        <>
          <h4>Keep Building</h4>
          <p>
            Keep working on a bot you have already created.
          </p>
          <p>
            Warning! Old bots can have strange behaviors as challenges are updated.
            An old bot should never be used in a tournament.
          </p>
          <ul>
            { existingBots.map((existingBot) => (
              <li>
                <StandardOutboundLink href={urlConstructor(existingBot)}>
                  {existingBot.botId}
                </StandardOutboundLink>
              </li>
            )) }
          </ul>
        </>
        )}
      </div>
    </>
  );
};

Create.propTypes = {
  currentChallenge: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  playerId: PropTypes.string,
  eventId: PropTypes.string,
  playerSecretKey: PropTypes.string,
  player2: PropTypes.string,
  onCreate: PropTypes.func,
};

Create.defaultProps = {
  playerId: null,
  eventId: null,
  playerSecretKey: null,
  player2: undefined,
  onCreate: () => {},
};

export default Create;
