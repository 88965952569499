import { useCookies } from 'react-cookie';

const usePreviousBots = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const removeBot = (botToRemove) => {
    const updatedBotList = Object.values(cookies.botList || {})
      .filter((bot) => bot.botId !== botToRemove);
    setCookie(
      'botList',
      updatedBotList.reduce((aggregate, bot) => ({ ...aggregate, [bot.botId]: bot }), {}),
      { path: '/', sameSite: 'strict' },
    );
    if (cookies.botId === botToRemove) {
      removeCookie('botId', { path: '/', sameSite: 'strict' });
    }
  };

  const switchBot = (botId) => {
    const botList = cookies?.botList || {};
    const bot = botList[botId];
    if (botId && bot) {
      setCookie('botId', botId, { path: '/', sameSite: 'strict' });
    } else {
      removeCookie('botId', { path: '/', sameSite: 'strict' });
    }
  };

  const addBot = (bot) => {
    const updatedBotList = {
      ...cookies.botList,
      [bot.botId]: bot,
    };
    setCookie('botList', updatedBotList, { path: '/', sameSite: 'strict' });
    setCookie('botId', bot.botId, { path: '/', sameSite: 'strict' });
  };

  return {
    removeBot, switchBot, addBot,
  };
};

export default usePreviousBots;
